<template>
  <div>
    <div class="routing-box position-relative">
      <div class="col-xl-12 banner-text routing-text">
        <h1 class="banner-font" v-html="this.$store.state.bannertext">
        </h1>
      </div>
      <br/>
      <br/>
      <div class="col-xl-12 position-relative-navbar-top routing-booking">
        <ul class="nav nav-btns ">
          <li :class="roundbttn" v-on:click="tabzflight('roundbttn')">Round Trip</li>
          <li :class="onebttn" v-on:click="tabzflight('onebttn')">One-Way</li>
        </ul>
      </div>
      <div class="col-xl-12 hero-search-bar tabz-hero message">
        <div class="inner-wrapper-new">
          <div class="hero-tabz-active">
            <form class="form-outer">
              <div class="form-wrap" id="inner-form-calendar">
                <div class="outer-st-top d-inlne">
                  <div class="select_1" style="width: 50%;  text-align: center">
                    <select class="js-data-example-ajax119" id="abc1"
                            style="border-color:white;display:none"></select>
                  </div>

                  <div :class="line_st"></div>
                  <div class="select_2" style="width: 50%;text-align;margin-top: 0px;">
                    <select class="js-data-example-ajax319" id="ststst1"
                            style="border-color:white; display:none;"></select>
                  </div>
                </div>
              </div>
              <!-- RESPONSIVE DEPARTURE  -->
              <div class="both-design-outer">
                <div class="line-responsive-boths"></div>
                <div class="departure-style">
                  <div class="design-departure-outer">
                    <label id="labelChanged" for="checkone" class="fade-down">Departure Form</label>
                    <input id="checkone" type="checkbox"/>
                    <div class="testone">
                      <div class="p-allone">
                        <div class="modal-header poper">
                          <label for="checkone" class="close departure-form">&times;</label>
                        </div>
                        <div class="travelers-detail-inner">
                          <div class="departure-select">
                            <br/>
                            <br/>
                            <select form="FormCalcInput" id="calcOption3"
                                    class="js-data-example-ajax7"
                                    style="border-color:white;"></select>
                          </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <label for="checkone" @click="departure_mbl_set()" class="departure-bttn">
                          Apply
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Going -->
                <div class="going-departure-outer-design" @click="hero_tab()">
                  <div :class="going_form_style" id="going_form_style">
                    <label id="labelChanged1" for="checktwo" class="fade-down"
                    >Going to</label
                    >
                    <input id="checktwo" type="checkbox"/>
                    <div class="testtwo">
                      <div class="p-all-two">
                        <div class="modal-header poper">
                          <label for="checktwo" class="close traveler-form">&times;</label>
                        </div>
                        <div class="travelers-detail-inner">
                          <div class="inner-responsive-select">
                            <br/>
                            <br/>
                            <select form="FormCalcInput1" id="calcOption4"
                                    class="js-data-example-ajax8"
                                    style="border-color:white;text-align:left;"></select>
                          </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <label for="checktwo" @click="going_mbl_set()" class="going-bttn">
                          Apply
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end -->
              <div class="form-wrap" id="inner-form-calendar">
                <div class="outer-sty">
                  <div class="line-date-mid">
                    <div :class="line_border"></div>
                  </div>
                  <div class="row text-center p-0" style="text-align: center;height: 20px">
                    <div class="col-md-6  col-lg-6 col-sm-6  " style=" text-align: center">
                      <label :class="calendar_deaprture" for="daterangeid">Departure</label>
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-6  " style=" text-align: center">
                      <label :class="calnder_class" for="daterangeid">Return</label>
                    </div>
                  </div>


                  <input class="calendar-inner-wrapper d-none" value="null" @change="daterange()"
                         id="daterangeid"
                         name="daterange">
                  <div class="row text-center p-0" style="text-align: center">
                    <div class="col-md-6 col-lg-6 col-sm-6 p-0" style=" text-align: center">
                      <label for="daterangeid"
                             :class="right_input_date">{{ serachdata.departure_date }} </label>
                    </div>

                    <div class="col-md-6 col-lg-6 col-sm-6 p-0" style=" text-align: center">
                      <label for="daterangeid"
                             :class="return_cale_inp">{{ serachdata.return_date }} </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-wrap" id="inner-form-travel">
                <div class="bg-travel">
                  <label for="check" class="fade-down"
                  >Travelers</label
                  >
                  <div class="travelars-content">
                    <label for="check" class="fade-down">
                      <div class="relative">0{{
                          serachdata.adult + serachdata.children + serachdata.infants
                        }}
                      </div>
                    </label>
                  </div>
                  <label for="check" class="fade-down class"
                  >Class</label
                  >
                  <div class="travelars-content-eco">
                    <label for="check" class="fade-down">
                      <div class="relative-eco">{{ cahangetab }}</div>
                    </label>
                    <input type="hidden" name="cabinclass" v-model="serachdata.cabinclass"/>
                  </div>
                  <div class="line-st2"></div>
                  <input id="check" type="checkbox"/>
                  <div class="test">
                    <div class="p-all new-padding">
                      <h3 class="travelers-heading">
                        Travelers
                      </h3>
                      <div class="modal-header poper">
                        <label for="check" class="close traveler-form-close-bttn">&times;</label>
                      </div>
                      <div class="travelers-detail-inner">
                        <div class="wrap-travel">
                          <p class="inner-text-trav">Adults</p>
                          <button v-on:click.prevent="adultdecrement" class="travel-btn-outer">-
                          </button>
                          <div class="width">{{ serachdata.adult }}</div>
                          <input type="hidden" name="adult" v-model="serachdata.adult"/>
                          <input type="hidden" name="searchtype" v-model="serachdata.searchtype"/>
                          <button v-on:click.prevent="adultincrement" class="travel-btn-outer">+
                          </button>
                        </div>
                        <div class="wrap-travel">
                          <p class="inner-text-trav">Children <br><span>2 - 12 Years</span></p>
                          <!--                                                <button v-on:click.prevent="serachdata.childrendecrement"-->
                          <!--                                                        class="travel-btn-outer mt-3">- -->
                          <!--                                                </button>-->
                          <button v-on:click.prevent="childrendecrement"
                                  class="travel-btn-outer mt-3">-
                          </button>

                          <div class="width mt-3">{{ serachdata.children }}</div>
                          <input type="hidden" name="children" v-model="serachdata.children"/>
                          <button v-on:click.prevent="childrenincrement"
                                  class="travel-btn-outer mt-3">+
                          </button>
                        </div>
                        <div class="wrap-travel">
                          <p class="inner-text-trav">Infants <br> <span>0 - 23 Months</span></p>
                          <button v-on:click.prevent="infantsdecrement"
                                  class="travel-btn-outer mt-3">-
                          </button>
                          <div class="width mt-3">{{ serachdata.infants }}</div>
                          <input type="hidden" name="infants" v-model="serachdata.infants"/>
                          <button v-on:click.prevent="infantsincrement"
                                  class="travel-btn-outer mt-3">+
                          </button>
                        </div>
                      </div>
                      <h3 class="travelers-heading">
                        Class
                      </h3>
                      <div class="travel-class-option">
                        <button
                            type="button"
                            v-bind:class="economy_c"
                            @click="cahangeTabF('Economy')"
                        >
                          Economy
                        </button>
                        <button
                            type="button"
                            v-bind:class="business_c"
                            @click="cahangeTabF('Business')"
                        >
                          Business
                        </button>
                        <button
                            type="button"
                            v-bind:class="first_c"
                            @click="cahangeTabF('First')"
                        >
                          First
                        </button>
                      </div>
                      <label for="check" class="apply-btn">
                        Apply
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-wrap" id="inner-form-header"></div>
              <div class="search-bttn-outer">
                <button @click="submitsearch()"
                        type="button"
                        class="search-bttn-inner primary-btn"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $a from 'jquery';
import 'select2';


export default {
  name: "SearchHome",
  data(baseImageURL = '') {
    return {
      calndercount: 0,
      myValue: '',
      time1: null,
      isHidden: false,
      description: "defualt description",
      meta: 'defualt meta',
      current_url: '/',
      title: 'Home',

      post: [],
      baseImageURL: baseImageURL,
      bannertext: 'home',
      count: 5,
      suggestionAttribute: 'title',
      suggestions: ['lhr', 'khi', 'Isb'],
      selectedEvent: "",
      key: null,
      serachdata: {
        adult: 1,
        children: 0,
        infants: 0,
        from: '',
        to: '',
        searchtype: 'round',
        cabinclass: 'economy',
        departure_date: '',
        return_date: ''
      },
      searchTerm: '',
      first_c: 'button.default-btn',
      economy_c: 'button.default-btn-active',
      business_c: 'button.default-btn',
      cahangetab: 'Economy',
      options: [],
      roundbttn: 'navbar-flights',
      onebttn: 'navbar-flights-deactive',
      calnder_class: "return-lable-calendar-st2",
      calendar_deaprture: "calendar-inner-wrapper1",
      line_border: "line-st-home",
      line_st: "line-st mt-52",
      right_input_date: 'label inner-date-ranger-right',
      going_form_style: 'going-dynamic-st going-left',
      return_cale_inp: 'inner-date-ranger-left',
    }
  },
  components: {},
  beforeRouteEnter(to, from, next) {
    this.$store.state.bgyugobanner = '';
    this.mounted();
    next();
  },
  mounted() {
    // this.serachdata = JSON.parse(localStorage.getItem('home_search'));
    // console.log(this.serachdata);

    var a = 0;
    // $('#daterangeid').daterangepicker();
    $(function () {
      const dateToday = new Date();
      $('input[name="daterange"]').daterangepicker({
        opens: 'left',
        minDate: dateToday,
      }, function (start, end, label) {
        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
      });
    });
    $('#daterangeid').val(null);
    var newdatearray = [];
    const vm = this;


    $("#daterangeid").change(function () {

// console.log(vm.serachdata);

      // if(vm.serachdata.from==null || vm.serachdata.from==''){
      //     alert('select from')
      // }else if(vm.serachdata.to==null  || vm.serachdata.to==''){
      //     alert('select To')
      // }else{

      if (vm.calndercount == 0) {
        var date = null;
        newdatearray = [];
      } else {
        var date = $(this).val();
        newdatearray = date.split('-');
      }
      vm.calndercount++;
      if (newdatearray.length == 2) {
        vm.serachdata.departure_date = newdatearray[0];
        vm.serachdata.return_date = newdatearray[1];
        if (vm.serachdata.departure_date == vm.serachdata.return_date) {
          alert('select valid date range');
          return false;
        }
        vm.serachdata.return_date = vm.formatDate(vm.serachdata.return_date);
        vm.serachdata.departure_date = vm.formatDate(vm.serachdata.departure_date);
      } else {
        vm.serachdata.departure_date = newdatearray[0];
        vm.serachdata.return_date = '';
        vm.formatDate(vm.serachdata.return_date)
      }
      // }

    });
    setTimeout(() => {
          vm.slectset();
        }
        , 1100);
    $a('#calcOption3').on('change', function () {
      var v = $(this).find(':selected').text();

      $a('#labelChanged').html(v);
    });
    $a('#calcOption4').on('change', function () {
      var v = $(this).find(':selected').text();
      $a('#labelChanged1').html(v);
    });
  },
  methods: {
    departure_mbl_set() {

      this.serachdata.from = $a('.js-data-example-ajax7').val();
      console.log(this.serachdata.from);


    }
  , from_set_desktop() {

    // alert($a('.js-data-example-ajax1').val());
    this.serachdata.from = $a('.js-data-example-ajax1').val();
    console.log(this.serachdata.from);

  }, to_set_desktop() {

    this.serachdata.to = $a('.js-data-example-ajax3').val();
    console.log(this.serachdata.to);


  }, going_mbl_set() {

    this.serachdata.to = $a('.js-data-example-ajax8').val();
    console.log(this.serachdata.to);


  },

    reinitializeDatePicker() {
      // Reset the selected date and toggle the date picker
      this.selectedDate = null;

    },
    increment() {
      this.count++;
    },
    decrement() {
      if (this.count > 0) {
        this.count--;
      }
    },
    slectset() {

      $a('.js-data-example-ajax119').select2({
        ajax: {
          url: 'https://api.yugo.pk/api/airports/' + this.serachdata,
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: data
            };
          },
          cache: true
        },
        placeholder: 'Departure From',
        // minimumInputLength: 1,
        templateResult: templateResult,
        templateSelection: templateSelection
      });
      this.line_st = 'line-st';
      $a('.js-data-example-ajax319').select2({
        ajax: {
          url: 'https://api.yugo.pk/api/airports/' + this.serachdata,

          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: data
            };
          },
        },
        placeholder: 'Going To',
        templateResult: templateResult,
        templateSelection: templateSelection

      });
      $a('.js-data-example-ajax7').select2({
        ajax: {
          url: 'https://api.yugo.pk/api/airports/' + this.serachdata,
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: data
            };
          },
          cache: true
        },
        placeholder: 'Departure From',
        minimumInputLength: 1,
        templateResult: templateResult,
        templateSelection: templateSelection
        // dropdownParent: $("#exampleModal_going"),
      });
      $a('.js-data-example-ajax8').select2({
        // going_form_style == 'going-dynamic-st going-left',
        ajax: {
          url: 'https://api.yugo.pk/api/airports/' + this.serachdata,
          processResults: function (data) {
            // Transforms the top-level key of the response object from 'items' to 'results'
            return {
              results: data
            };
          },
        },
        placeholder: 'Going To',
        templateResult: templateResult,
        templateSelection: templateSelection

      });
    },
    adultincrement() {
      if (this.serachdata.adult + this.serachdata.children + this.serachdata.infants <= 10) {
        this.serachdata.adult++;
      }
    },
    formatDate(date1) {
      const date = new Date(date1);
      const options = {day: 'numeric', month: 'short', year: 'numeric'};
      return date.toLocaleDateString('en-pk', options);
    },
    adultdecrement() {
      if (this.serachdata.adult > 1) {
        this.serachdata.adult--;
      }
      if (this.serachdata.adult > this.serachdata.infants) {

      } else {
        this.serachdata.infants--;
      }
    },
    childrenincrement() {
      if (this.serachdata.adult + this.serachdata.children + this.serachdata.infants <= 10) {

        this.serachdata.children++;
      }
    },
    childrendecrement() {
      if (this.serachdata.children > 0) {
        this.serachdata.children--;
      }
    },
    infantsincrement() {
      if (this.serachdata.adult > this.serachdata.infants && this.serachdata.adult + this.serachdata.children + this.serachdata.infants < 10) {

        this.serachdata.infants = this.serachdata.infants + 1;
      }
    },
    infantsdecrement() {
      if (this.serachdata.infants > 0) {
        this.serachdata.infants--;
      }
    },
    submitsearch() {
      console.log(2)
      var date = $('#daterangeid').val();
      var newdatearray = date.split('-');
      // console.log(newdatearray);
      var form = $('.js-data-example-ajax119').val();
      var to = $('.js-data-example-ajax319').val();

      if (form != null || to != null) {
        if (to == null) {
          alert('please select the Arial City');
          return 0;
        } else if (form == null) {
          alert('please select the Departure City');
        }
      } else {
        var form = $('.js-data-example-ajax7').val();
        var to = $('.js-data-example-ajax8').val();
      }

      this.serachdata.departure_date = newdatearray[0];
      this.serachdata.return_date = newdatearray[1];

      this.checkDeparture = new Date(newdatearray[0]);
      this.checkReturn = new Date(newdatearray[1]);
      if (this.checkDeparture.getTime() === this.checkReturn.getTime()) {
        alert('select valid date range');
        return false;
      }
      // console.log(newdatearray);
      // if(newdatearray.length==2){
      //     vm.serachdata.departure_date= newdatearray[0];
      //     vm.serachdata.return_date= newdatearray[1];
      // }else{

      //     vm.serachdata.departure_date= newdatearray[0];
      //     vm.serachdata.return_date= null;
      //     // vm.serachdata.departure_date= newdatearray[0];
      // }

      this.serachdata.from = form.replace(/\s/g, "");
      this.serachdata.to = to.replace(/\s/g, "");
      // console.log(this.serachdata)
      localStorage.setItem('home_search', JSON.stringify(this.serachdata));
      window.location.reload();
      // this.$router.push("/flight-search");

    },
    cahangeTabF(cahangetab) {
      if (cahangetab == 'Economy') {
        this.economy_c = 'button.default-btn-active';
        this.business_c = 'button.default-btn';
        this.first_c = 'button.default-btn';
        this.serachdata.cabinclass = 'economy';
      }
      if (cahangetab == 'Business') {
        this.economy_c = 'button.default-btn';
        this.business_c = 'button.default-btn-active';
        this.first_c = 'button.default-btn';
        this.serachdata.cabinclass = 'business';
      }
      if (cahangetab == 'First') {
        this.economy_c = 'button.default-btn';
        this.business_c = 'button.default-btn';
        this.first_c = 'button.default-btn-active';
        this.serachdata.cabinclass = 'first';
      }
      ;
      this.cahangetab = cahangetab;
    },
    tabzflight(tab_name) {
      if (tab_name == 'roundbttn') {
        this.roundbttn = 'navbar-flights';
        this.onebttn = 'navbar-flights-deactive';
        this.serachdata.searchtype = "roundbttn";
        this.calnder_class = "return-lable-calendar-st2";
        this.calendar_deaprture = "label calendar-inner-wrapper1";
        this.line_border = "line-st-home";
        this.right_input_date = 'label inner-date-ranger-right';
        this.return_cale_inp = 'inner-date-ranger-left';


        // $(function () {
        //     const dateToday = moment(new Date());
        //     $('input[name="daterange"]').daterangepicker({
        //         opens: 'left',
        //         minDate: dateToday,
        //         startDate: dateToday, // Set the default start date to today's date
        //         endDate: dateToday,   // Set the default end date to today's date
        //
        //     }, function (start, end, label) {
        //     });
        // });  // $(function () {
        //     const dateToday = moment(new Date());
        //     $('input[name="daterange"]').daterangepicker({
        //         opens: 'left',
        //         minDate: dateToday,
        //         startDate: dateToday, // Set the default start date to today's date
        //         endDate: dateToday,   // Set the default end date to today's date
        //
        //     }, function (start, end, label) {
        //     });
        // });
        $(function () {
          const dateToday = new Date();
          $('input[name="daterange"]').daterangepicker({
            opens: 'left',
            minDate: dateToday,
          }, function (start, end, label) {
            console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          });
        });

        this.calndercount =0;
      }
      if (tab_name == 'onebttn') {
        this.roundbttn = 'navbar-flights-deactive';
        this.onebttn = 'navbar-flights';
        this.serachdata.searchtype = "onebttn";
        this.calnder_class = "return-lable-calendar-st2 d-none";
        this.return_cale_inp = 'inner-date-ranger-left d-none';
        this.calendar_deaprture = "label calendar-inner-wrapper-single1";
        this.line_border = "line-st-home d-none";
        this.right_input_date = 'label inner-date-ranger-rigt-middle';

        //     var dateToday = moment(new Date());

        //     // const dateToday = new Date();

        //     console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        // $('input[name="daterange"]').daterangepicker({
        //     opens: 'left',
        //     minDate: dateToday,
        //     singleDatePicker: true,
        // }, function (start, end, label) {
        //     console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
        // });
        $(function () {
          const dateToday = new Date();
          $('input[name="daterange"]').daterangepicker({
            opens: 'left',

            singleDatePicker: true,
            minDate: dateToday,
          }, function (start, end, label) {
            console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
          });
        });


        this.calndercount =0;
        // $(function () {
        //     const dateToday = moment(new Date());
        //     $('input[name="daterange"]').daterangepicker({
        //         opens: 'left',
        //         minDate: dateToday,
        //         startDate: dateToday, // Set the default start date to today's date
        //         endDate: dateToday,   // Set the default end date to today's date
        //         singleDatePicker: true,
        //     }, function (start, end, label) {
        //         // Your callback function logic here
        //     });
        // });


      }
    },
  }
};
</script>
<style scoped>
input.select2-search__field {
  display: none !important;
}

.bg-travel {
  z-index: 9;
}

span.select2-selection.select2-selection--single, .select2-container--default .select2-selection--single, span#select2-ststst-container, span.select2-selection.select2-selection--single {
  border-color: #fff !important;
}

select#abc1 {
  width: 200px;
  background: transparent;
  border: none;
}

select#ststst1 {
  width: 200px;
  background: transparent;
  border: none;
  float: right;
  text-align: right;
}

.select-two-st-wrapper {
  width: 27%;
  position: relative;
  top: 2px;
  left: 56%;
}

.select-one-st-wrapper {
  width: 11%;
  position: relative;
  top: 2px;
  left: 5%;
}

.select2-container {
  width: 348px !important;
}

input.sbx-facebook__input-placeholder {
  display: none !important;
}

p.auto-text-wrap {
  width: 25%;
  color: #16244d;
  font-size: 15px;
  line-height: 20px;
  float: left;
}

.bold-box-text {
  border: 1px solid #007cff;
  width: 4%;
  text-align: center;
  color: #007cff;
  float: right;
}

::placeholder {
  color: #00224f;
}

li.navbar-flights {
  padding: 3px 30px;
  border-radius: 30px;
  color: #00224f;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffff;
}

li.navbar-flights-deactive {
  padding: 2px 29px;
}
</style>