import Vue from "vue";
import Vuex from "vuex";

let dom = window.location.hostname;
let url = 'https://yugo.pk.com/';
let site  = 'https://yugo.pk.com.com/';


if (dom == 'localhost') {
    url = 'http://127.0.0.1:8000/';
}if (dom == 'localhost') {
    site = 'http://127.0.0.1:8000/';
}
Vue.use(Vuex);

export default new Vuex.Store({
    state: {

        baseURL: url+'api/',
        siteURL: site,
        imagesBaseURL: url,
        loader_status:false,
        baseImageURL: url,
        acesstoken: null,
        bannertext: null,
        bgyugobanner: null,
        page_icon: null
    },
    mutations: {},
    actions: {},
    modules: {},
});