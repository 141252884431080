<template>
  <div class="col-xl-12 float-left p-0">
    <div :class="this.$store.state.bgyugobanner" id="bg-no">
      <div class="main-header" id="header">
        <div class="wrapper-top">
          <div class="on-show-hie">
            <div class="container-fluid main-header">
              <nav class="navbar-top">
                <router-link :to="'/'" class="navbar-brand">
                  <img
                      src="@/assets/logo.svg"
                      class="svg-icon"
                  />
                </router-link>
                <div class="nav-links">
                  <ul
                      class="main-header-links list-unstyled m-0 p-0"
                  >
                    <li class="nav-active">
                      <router-link
                          :to="'/visas'"
                          class="header-font nav-link"
                      >Visas
                      </router-link>
                    </li>
                    <li class="nav-active">
                      <router-link :to="'/flights'"
                                   class="header-font nav-link"
                      >Flights
                      </router-link>
                    </li>
                    <li class="nav-active">
                      <router-link
                          :to="'/offers'"
                          class="header-font nav-link"
                      > Offers
                      </router-link>
                    </li>
                    <li class="nav-active">
                      <router-link
                          :to="'/packages'"
                          class="header-font nav-link"
                      >Packages
                      </router-link>
                    </li>
                    <li class="main-header dropdown" @click="isSectionVisible = !isSectionVisible">
                      <router-link
                          :to="''"
                          class="header-drop nav-link"
                          id="navbardrop">
                        <span v-if="login">{{ (user.name).substring(0, 8) }}</span>
                        <span v-else>My Account</span>
                      </router-link>
                      <div class="bottom-visible-box" v-if="isSectionVisible && login == false">
                        <div class="inner-padding-login">
                          <router-link class="" :to="'login'"> Login</router-link>
                        </div>
                        <div class="inner-padding-login">
                          <router-link class="" :to="'register-user'">Signup</router-link>
                        </div>
                      </div>
                      <!--                                            {{ isSectionVisible }}-->
                      <!--                                            {{ login }}-->
                      <div class="bottom-visible-box" v-if="isSectionVisible && login">
                        <div class="inner-padding-login">
                          <router-link class="" :to="'/user/dashboard'"
                          >{{ user.name }}
                          </router-link
                          >
                        </div>
                        <div class="inner-padding-login">
                          <router-link class="" :to="'/user/edit'"
                          >My Profile
                          </router-link
                          >
                        </div>
                        <div class="inner-padding-login">
                          <router-link class="" :to="'/my-trip'"
                          >My Trips
                          </router-link
                          >
                        </div>
                        <div class="inner-padding-login one-border-top">
                          <router-link class="" :to="'/yugo-rewards-check'"
                          >Yugo Rewards
                          </router-link
                          >
                        </div>
                        <div class="inner-padding-login">
                          <a @click="logout()">Logout</a>
                        </div>
                      </div>
                    </li>
                    <template v-if="login">
                      <li class="main-header contact-login-wrap"
                          @click="isSectionVisible1 = !isSectionVisible1">
                        <router-link
                            :to="''"
                            class="header-drop nav-link background"
                            id="navbardrop contact"
                        >
                          <span>Contact Us</span>
                        </router-link>
                        <div class="bottom-visible-box-contact" v-if="isSectionVisible1">
                          <div class="inner-padding-contact">
                            <a class="" href="tel:+923111123359"
                            >Call us
                            </a
                            >
                          </div>
                          <div class="inner-padding-contact">
                            <a href="https://api.whatsapp.com/send/?phone=923111123359&text&app_absent=0"
                               target="_blank"
                            >WhatsApp us
                            </a
                            >
                          </div>
                          <div class="inner-padding-contact">
                            <a href="https://g.page/Kaizen-Consulting-and-Services"
                               target="_blank"
                            >Find us
                            </a
                            >
                          </div>
                          <div class="inner-padding-contact">
                            <a href="mailto:info@yugo.pk"
                            >Write us
                            </a
                            >
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- NAV BURGER -->
      <div class="burger-nav">
        <Slide>
          <div class="burger-nav-outer">
            <router-link
                :to="'/visas'">
              <div class="burger-nav-st">
                Visas
              </div>
            </router-link>
            <router-link :to="'/flights'"
            >
              <div class="burger-nav-st">
                Flights
              </div>
            </router-link>
            <router-link :to="'/offers' ">
              <div class="burger-nav-st">
                Offers
              </div>
            </router-link>
            <router-link :to="'/packages' ">
              <div class="burger-nav-st">
                Packages
              </div>
            </router-link>
            <router-link :to="'' ">
              <div class="burger-nav-st">
                <div class="dropdown">
                  <button class="burger-nav-st-last new-box-contact" @click="toggleDropdown" mb-2>
                    Contact
                  </button>
                  <img src="@/assets/select-arrow.png" class="icon-drop" alt="">
                  <div class="dropdown-content" v-show="isDropdownOpen">
                    <a class="block-drop-linbks" href="tel:+923111123359">Call us </a>
                    <a class="block-drop-linbks"
                       href="https://api.whatsapp.com/send/?phone=923111123359&text&app_absent=0">WhatsApp
                      us</a>
                    <a class="block-drop-linbks"
                       href="https://g.page/Kaizen-Consulting-and-Services">Find us</a>
                    <a class="block-drop-linbks" href="mailto:info@yugo.pk">Write us</a>
                  </div>
                </div>
              </div>
            </router-link>
            <router-link :to="'login' ">
              <div class="burger-nav-st-last">
                Login / Signup
              </div>
            </router-link>
          </div>
        </Slide>
      </div>
      <!-- END -->
      <!-- Responsive -->

      <div class="banner-nav-links">
        <ul class="m-0 p-0 list-unstyled">
          <li class="d-inline-block align-top">
            <router-link :to="'/visas'">
              <button @click="toggleIcon('/visas')" style="padding: 0px !important;">
                <div class="icon-container">
                  <i class="icon" v-if="this.$route.path=='/visas'"
                     :class="'icon-background-2'"></i>

                  <i class="icon" v-else
                     :class="'icon-background-1'"></i>
                </div>
                <div class="nav-text-st-offer">
                  Visas
                </div>
              </button>
            </router-link
            >

          </li>
          <li class="d-inline-block align-top">
            <router-link :to="'/flights'">
              <button @click="toggleIcon('/flights')" style="padding: 0px !important;">
                <div class="icon-container">
                  <i class="icon" v-if="this.$route.path=='/flights'"
                     :class="'icon-background-4'"></i>
                  <i class="icon" v-else
                     :class="'icon-background-3'"></i>
                </div>
                <div class="nav-text-st-offer">
                  Flights
                </div>
              </button>
            </router-link
            >
          </li>
          <li class="d-inline-block align-top">
            <router-link :to="'/offers'">
              <button @click="toggleIcon('/offers')" style="padding: 0px !important;">
                <div class="icon-container">
                  <i class="icon" v-if="this.$route.path=='/offers'"
                     :class="'icon-background-6'"></i>
                  <i class="icon" v-else
                     :class="'icon-background-5'"></i>
                </div>
                <div class="nav-text-st-offer">
                  Offers
                </div>
              </button>
            </router-link
            >
          </li>
          <li class="d-inline-block align-top">
            <router-link :to="'/packages'">
              <button @click="toggleIcon('/packages')" style="padding: 0px !important;">
                <div class="icon-container">
                  <i class="icon" v-if="this.$route.path=='/packages'"
                     :class="'icon-background-8'"></i>
                  <i class="icon" v-else
                     :class="'icon-background-7'"></i>
                </div>
              </button>
              <div class="nav-text-st-offer" style="padding: 0px;margin-top: -4px;margin-left: 6%;">
                Packages
              </div>
            </router-link
            >
          </li>
        </ul>
      </div>


      <!-- WHATSAPP -->
      <div class="id-svg">
        <div id="img-svg-outer">
          <div class="inner-svg-all-layout">
            <a href="https://api.whatsapp.com/send/?phone=923111119846&text=I+want+to+know+more+about+.&type=phone_number&app_absent=0"
               target="_blank">
              <img src="@/assets/whatsapp-svg.jpg" class="w-100">
            </a>
          </div>
        </div>
      </div>
      <!-- END -->
      <!-- Home Search -->
      <div id="search" v-if="this.$route.path=='/flights' ">
        <SearchHome/>
        <!-- <BookAirlineSearch /> -->
      </div>
      <!-- Visa Search -->
      <div
          v-else-if="this.$route.path=='/visas'||this.$route.path=='/' || this.$route.path=='/offers' || this.$route.path=='/packages' || this.$route.path=='/packages/countries/all' || this.$route.path=='/packages/all'">
        <Search/>
      </div>
      <div class="d-none" id="book-search">
        <BookAirlineSearch/>
      </div>
    </div>
    <LoaderActive v-if="this.$store.state.loader_status"/>
  </div>
</template>
<script>
import {Slide} from 'vue-burger-menu';
import SearchHome from './SearchHome.vue';
import BookAirlineSearch from './BookAirlineSearch.vue';
import Search from './Search.vue';
import LoaderActive from '@/components/LoaderActive.vue';
import store from '@/store/index';

export default {
  name: "Header",

  data(baseImageURL = '') {
    return {
      description: "defualt description",
      meta: 'defualt meta',
      current_url: '/',
      title: 'Home',
      post: [],
      baseImageURL: baseImageURL,
      Slide: ' ',
    }
  },
  components: {SearchHome, Search, Slide, BookAirlineSearch, LoaderActive},
  data() {
    return {
      selectedCountryOption: null,
      currenturl: null,
      isSectionVisible: false,
      isSectionVisible1: false,
      user: [],
      login: false,
      isDropdownOpen: false,
      currentIcon1: 'icon1',
      currentIcon2: 'icon3',
      currentIcon3: 'icon5',
      currentIcon4: 'icon7',

    };
  },
  beforeRouteEnter(to, from, next) {
    // store.state.bgyugobanner = this.bgyugobanner;
    // store.state.page_icon = ""; // by defualt variable  and get slug and give to icon
    this.mounted();
    this.toggleIcon(this.$route.path);
    next();
  }, mounted() {

    this.$store.state.loader_status = true;


    if (localStorage.getItem('userData')) {
      this.user = JSON.parse(localStorage.getItem('userData'));
      this.login = true;
    }

    this.currenturl = this.$store.state.siteURL + this.$route.path;
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 50) {
        $("#header").addClass("active");
      } else {
        //remove the background property so it comes transparent again (defined in your css)
        $("#header").removeClass("active");
      }
    });
    $('#book-search').addClass('d-none')
    this.$store.state.loader_status = false;
  }, methods: {
    logout() {
      this.login = false;
      localStorage.removeItem("userData");
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleIcon(url) {


      this.$store.state.page_icon = "";
      if (url == "/visas") {
      } else if (url == "/flights") {
      } else if (url == "/offers") {
      } else if (url == "/packages") {
      }
    },
    initialize(to) {

      var url = to;

      if (url == "/visas") {
      } else if (url == "/flights") {
        this.$store.state.bgyugobanner = "bg-yugo";

      } else if (url == "/offers") {
      } else if (url == "/packages") {
      }
      this.$store.state.loader_status = true;


      if (localStorage.getItem('userData')) {
        this.user = JSON.parse(localStorage.getItem('userData'));
        this.login = true;
      }

      this.currenturl = this.$store.state.siteURL + this.$route.path;
      $(window).on("scroll", function () {
        if ($(window).scrollTop() > 50) {
          $("#header").addClass("active");
        } else {
          //remove the background property so it comes transparent again (defined in your css)
          $("#header").removeClass("active");
        }
      });
      $('#book-search').addClass('d-none')
      this.$store.state.loader_status = false;

    }

  },
};
</script>
<style scoped>
button {
  border: none !important;
  background: transparent;
}

.icon-container {
  width: 72px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.icon-background-1 {
  background-image: url("/public/visas-dark.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-2 {
  background-image: url("/public/visas-light.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-3 {
  background-image: url("/public/plane-dark-blue.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-4 {

  background-image: url("/public/plane-light.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-5 {

  background-image: url("/public/testing.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-6 {

  background-image: url("/public/offers-light.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;
}

.icon-background-7 {
  background-image: url("/public/packages-dark.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.icon-background-8 {
  background-image: url("/public/packages Blue.png");
  background-repeat: no-repeat;
  height: 35px;
  width: 40%;

}

.dropdown-content a {
  display: block;
  padding: 9px 13px;
  font-size: 16px;
}

img.icon-drop {
  width: 3%;
  font-weight: 900;
  align-items: end;
  margin-left: 8px;
  margin-top: 1px;
}

button.burger-nav-st-last.new-box-contact {
  border: none;
  background: transparent !important;
  border-top: none;
  padding: 0px !important;
  border: 1px solid #fff !important;
}

.dropdown {
  border: none !important;
  border-top: none !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

body {
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased !important;
}

.bottom-visible-box a {
  color: black;
  font-size: 14px;
  font-weight: 500 !important;
}

.bottom-visible-box a:hover {
  color: #047aff !important;
  text-decoration: none !important;
}

.inner-padding-login {
  padding: 0px 13px;
  cursor: pointer;
}

.bottom-visible-box {
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin: 8px -80px;
  float: left;
  width: 195px;
  text-align: left;
  text-align: left;
  padding: 5px 0px;
  height: 60px;
}

nav a {
  font-weight: 100;
}

.visa-bg-detail {
  width: 100%;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1170px;
}

div#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 11;
  transition: all 0.2s ease-in-out;
  height: auto;
  clear: both;
}

.wrapper-top {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

img.svg-icon {
  width: 141px;
}

ul.main-header-links.list-unstyled.m-0.p-0 {
  padding: 2px 0px !important;
  margin: 0px;
  align-items: center;
  display: -webkit-inline-box;
}

a.header-font.nav-link:hover:after {
  display: block;
}

a.header-font.nav-link:after {
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  content: '';
  display: none;
  position: absolute;
  background-color: #000;
  top: 26px;
}

.inner-padding-login.one-border-top {
  border-top: 1px solid #dddedf;
  margin: 0;
  margin-left: 12px;
  margin-right: 16px;
  padding: 6px 0px 0px;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.2505rem;
  padding-bottom: 0.2505rem;
  margin-right: 1.875rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.nav-links {
  width: 417px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 40px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 10000;
  transition: all 1s ease-in-out;
  height: auto;
  background-color: transparent;
  text-align: center;
  line-height: 40px;
}

.header.active {
  background: blue;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}

</style>


