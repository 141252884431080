import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
import test from "../views/Test.vue";
// import visa from "../views/Visa.vue";
import store from "@/store";
Vue.use(VueRouter);
import {createRouter, createWebHashHistory} from 'vue-router'


const routes = [
    
    {
        path: "",
        name: "Layout",
        component: Layout,
        children: [
            {
                path: '/',
                name: 'Home',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Home.vue"),

            },
            {
                path: 'flights',
                name: 'Flights',
                component: () =>
                        import("../views/Flights")
                        
            },
            {
                path:
                    'visas/',
                name: 'Visa',
                component:  () =>
                    import("../views/Visa")

            },
            {
                path:
                    'visa/:slug',
                name: 'Visa-detail',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Visa-detail.vue"),

            },
            {
                path:
                    'offers/',
                name: 'Offers',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Offers.vue"),

            },
            {
                path:
                    'offers/:slug',
                name: 'OfferDetail',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/OfferDetail.vue"),

            },
            {
                path:
                    'flight-search',
                name: 'FlightsSearch',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FlightsSearch.vue"),

            },
            {
                path:
                    '/airline/:slug',
                name: 'Airline',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Airline.vue"),

            },
            {
                path:
                    'packages',
                name: 'Packages',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Packages.vue"),

            },
            {
                path:
                    'package/:slug',
                name: 'Packages-detail',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Packages-detail.vue"),
            },
            {
                path:
                    'passenger-details/:slug',
                name: 'PassengerDetail',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/PassengerDetail.vue"),
            },
            {
                path:
                    'visa-payments/',
                name: 'VisaPayments',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/VisaPayments.vue"),

            },
            {
                path:
                    'packages/category/:slug',
                name: 'CategoryAdventure',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/CategoryAdventure.vue"),

            },
            {
                path:
                    'packages/country/:slug',
                name: 'PackageCountry',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/PackageCountry.vue"),
            },
            {
            path:
            'flights/:location1/:location2/:name',
            name: 'RoutesListing',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/RoutesListing.vue"),
            },
            {
            path:
            'topdestination',
            name: 'TopDestination',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/TopDestination.vue"),
            },
            {
            path:
            'packages_passengers_info',
            name: 'PackagePassenger',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/PackagePassenger.vue"),
            },
            {
            path:
            'flightpassenger/',
            name: 'FlighPassengerDetail',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/FlighPassengerDetail.vue"),
            },
            {
            path:
            '/place_order_package',
            name: 'ReservationDetail',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/ReservationDetail.vue"),
            },
            {
            path:
            'flightsummary/',
            name: 'FlightSummary',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/FlightSummary.vue"),
            },
            {
            path:
            'login/',
            name: 'Login',
            component: () =>
                import(/* webpackChunkName: "about" */ "../views/Auth/Login.vue"),
            },
            {
                path:
                'register-user/',
                name: 'RegisterUser',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Auth/RegisterUser.vue"),
                },
            {
                path:
                'password/reset',
                name: 'Forget',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/Auth/Forget.vue"),
            },
            {
                path:
                'yugo-rewards',
                name: 'YugoRewards',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/YugoRewards"),
           },
            {
                path:
                'sitemap',
                name: 'Sitemap',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/Sitemap"),
            },
            {
                path:
                'about-us',
                name: 'AboutUs',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/AboutUs"),
            },
            {
                path:
                'term-conditions',
                name: 'TermsCondition',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/TermsCondition"),
           },
            {
                path:
                'careers',
                name: 'Career',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/Career"),
           },
            {
                path:
                'contact-us',
                name: 'ContactUs',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/ContactUs"),
           },
           {
                path:
                'privacy-policy',
                name: 'PrivacyPolicy',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/PrivacyPolicy"),
            },  
           {
                path:
                'latest-travel-update',
                name: 'Covid',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/FooterBook/Covid"),
            },  
           {
                path:
                'packages/countries/all',
                name: 'CompleteDestinat',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/CompleteDestinat.vue"),
            },  
           {
                path:
                'packages/all',
                name: 'PackageSliderAll',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/PackageSliderAll.vue"),
            },  
           {
                path:
                'user/dashboard',
                name: 'UserDashboard',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/DashboardBook/UserDashboard.vue"),
            },  
           {
                path:
                'packagefilter',
                name: 'packagefilter',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/packagefilter.vue"),
            },  
           {
                path:
                'user/edit',
                name: 'UserEdit',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/DashboardBook/UserEdit.vue"),
            },  
           {
                path:
                'my-trip',
                name: 'Trips',
                component: () =>
                    import(/* webpackChunkName: "about" */ "../views/DashboardBook/Trips.vue"),
            },  
           
        ]
       
    },
    {
        path:'/test2',
        name: 'Test',
        component: test,
    }, 
 
]
const router = new VueRouter({
    routes,
    mode: 'history',
});

// router.beforeEach((to, from, next) => {
//     // Get the component for the next route
//     const component = router.getMatchedComponents(to)[0];
//   console.log(component.mounted);
//     // Call the mounted function on the component
//     // component.mounted();
//     store.state.bgyugobanner = "bg-yugo";
//     alert(0)
//     // Call the next function to allow navigation to proceed
//     next();
//   });


export default router;
