import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCollapse from 'vue2-collapse'
import VueInstant from 'vue-instant'
import 'vue-instant/dist/vue-instant.css'
import VueSession from 'vue-session';
import Select2 from 'v-select2-component';
import TDatePicker from "t-datepicker";
import VueMeta from 'vue-meta';

Vue.component('Select2', Select2);
Vue.use(VueSession)
Vue.use(VueInstant)
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.use(VueCollapse)
Vue.use(VueMeta)
export const bus = new Vue();
 

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
