<template>
    <div>
        <p>ddjb</p>
    </div>




</template>

<script>
    export default {
        data() {
            return {
                // currentIcon1: 'icon1',
                // currentIcon2: 'icon3',
                // currentIcon3: 'icon5',
                // currentIcon4: 'icon7',


            };
        },
        methods: {

        },
    };
</script>


<!--<style>-->
<!--    .banner-nav-links{-->
<!--        display: block !important;-->
<!--    }-->
<!--    .icon-container {-->
<!--        width: 100px;-->
<!--        height: 100px;-->
<!--        display: flex;-->
<!--        justify-content: center;-->
<!--        align-items: center;-->
<!--        border: 1px solid #ccc;-->
<!--    }-->

<!--    .icon-background-1 {-->
<!--        background-image: url("/public/visas-dark.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->
<!--    .icon-background-2 {-->
<!--        background-image: url("/public/visas-light.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->

<!--    .icon-background-3 {-->
<!--        background-image: url("/public/plane-dark-blue.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->

<!--    .icon-background-4 {-->

<!--        background-image: url("/public/plane-light.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->
<!--    .icon-background-5 {-->

<!--        background-image: url("/public/testing.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->

<!--    .icon-background-6 {-->

<!--        background-image: url("/public/offers-light.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->
<!--    }-->
<!--    .icon-background-7 {-->
<!--        background-image: url("/public/packages-dark.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->

<!--    .icon-background-8 {-->
<!--        background-image: url("/public/packages Blue.png");-->
<!--        background-repeat: no-repeat;-->
<!--        height: 35px;-->
<!--        width: 40%;-->

<!--    }-->
<!--</style>-->