<template>
  <div>
    <Header ref="headerComponent"/>
    <!--    :key="$route.path"-->
    <router-view :key="$route.fullPath"></router-view>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer
  }, methods: {
    initializeHeader(to) {
      const headerComponent = this.$refs.headerComponent;
      if (headerComponent) {
        headerComponent.initialize(to);

        $('#bg-no').removeClass('bg-no');
        if (to.path == "/flights") {
          this.$store.state.bgyugobanner = "bg-yugo";

        }

      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.initializeHeader(to.fullPath);
    // console.log();
    next();
  },
  beforeRouteLeave(to, from, next) {
    // Call your function when leaving the current route
    //  alert('beforeRouteLeave')

    // Proceed with leaving the route
    next();
  },
  beforeRouteEnter(to, from, next) {
    // Call your function when entering the new route
    // This guard is triggered before the component is created
    // alert('beforeRouteEnter')
// when route refresh it and new route
    // Proceed with the route navigation
    next();
  },
};


</script>
