<template>
<div class="new-home-banner visa-banner text-center float-left col-xl-12 message">
    <div class="wrapper">
        <div class="position-relative float-left col-xl-12 p-0">
            <h1 class="rubik-regular mb-0" v-html="this.$store.state.bannertext"></h1>
        </div>
       
        <div class="pdng-banner" v-if="visa_list==visa || this.$route.path=='/'">
            <div class="col-xl-12 p-listing-search-bar text-left float-left">
                <span><img src="@/assets/arrow-visa-bg.png" class="wrap-arrow-country"></span>
                <select class="float-left country" id="visasdropdown">
                    <option value="" selected disabled>Select Country</option>
                    <option class="float-left" v-for="visa in visa_options"
                        :value="visa.slug" :key="visa.slug" :v-model="visa">
                        {{ visa.item_name }}
                    </option>
                </select>  
                <div class="submit-btn mt-1">
                    <button type="submit" v-on:click="searchbutton()" class="form-btn visa">Search</button>
                </div>
            </div>
        </div>
        <div class="pdng-banner" v-if="$route.path=='/packages' ||   $route.path=='/visas' || $route.path=='/offers' ||   this.$route.path=='/packages/countries/all' || this.$route.path=='/packages/all'">
            <div class="col-xl-12 p-listing-search-bar text-left float-left">
                <span><img src="@/assets/arrow-visa-bg.png" class="wrap-arrow-country"></span>
              
             <div v-if="$route.path=='/packages' || this.$route.path=='/packages/countries/all' || this.$route.path=='/packages/all'"> 
                <select class="float-left country" id="pacakgesdropdown">
                    <option class="float-left" value="" selected disabled>Select Package</option>
                    <option class="float-left" v-for="visa in visa_options_packages"
                        :value="visa.slug" :key="visa.slug" :v-model="visa">
                        {{ visa.package_name }}
                    </option>
                </select>  
             </div>
            <div v-if="$route.path=='/visas'"> 
                <select class="float-left country" id="visasdropdown">
                    <option class="float-left" value="" selected disabled>Select Country</option>
                    <option class="float-left" v-for="visa in visa_options"
                        :value="visa.slug" :key="visa.slug" :v-model="visa">
                        {{ visa.item_name }}
                    </option>
                </select>  
             </div>
            <div v-if="$route.path=='/offers'"> 
                <select class="float-left country" id="offersdropdown">
                    <option class="float-left" value="" selected disabled>Select Offer</option>
                    <option class="float-left" v-for="visa in visa_options_offers"
                        :value="visa.slug" :key="visa.slug" :v-model="visa">
                        {{ visa.name }}
                    </option>
                </select>  
             </div>
                <div class="submit-btn">
                    <button type="button" @click="searchbutton" class="form-btn visa">Search</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import SearchHome from './SearchHome.vue';
import axios from "axios";
export default {
    name: "Search",
    data(baseImageURL = '') {
            return {
                description: "defualt description",
                meta: 'defualt meta',
                current_url: '/',
                title: 'Home',
                post: [],
                baseImageURL: baseImageURL,
            }
        },
    components: {SearchHome},
        data() {
            return {
                selectedCountryOption : null,     
                currenturl : null,
                visa_list:" ",
                visa_options: [
                    { name: "Select Country", id: null },
                ],
                visa_options_packages: [
                    { name: "Select Packages", id: null },
                ],
                visa:null,
                visa_options_offers: [
                    { name: "Select Offers", id: null },
                ],
            };
        }, beforeRouteEnter(to, from, next) {
            store.state.bgyugobanner = this.bgyugobanner;
            this.mounted();
            next();
        }, 
        mounted() {
           
            const vm=this;
            this.currenturl = this.$store.state.siteURL+ this.$route.path;
           
            axios.get('https://api.yugo.pk/api/packages/header', {
                // post_slug: vm.post_slug,
                headers: {}
            })
            .then(function (response) { 
                vm.visa_options_packages = response.data.packages;
                // console.log(vm.visa_options_packages)
                
            }).catch(error => {
                if (error) {
                    console.log(error);
                }
            });



            axios.get('https://api.yugo.pk/api/visa/header', {
                        // post_slug: vm.post_slug,
                headers: {}
            })
            .then(function (response) { 
                vm.visa_options = response.data.visas;
                
            }).catch(error => {
                if (error) {
                    console.log(error);
                }
            });

            axios.get('https://api.yugo.pk/api/offers/header', {
                // post_slug: vm.post_slug,
                headers: {}
            })
            .then(function (response) {
                // console.log(response)
                vm.visa_options_offers = response.data.campaign ;
                
            }).catch(error => {
                if (error) {
                    console.log(error);
                }
            });
         },
        methods: {
            fntc(){
                alert('amna');
            },
            searchbutton(){
                if(this.$route.path=="/offers")
                {
                    var slug= $('#offersdropdown').val();
                    this.$router.push("/offers/"+slug);
                }
                if(this.$route.path=="/packages")
                {
                    var slug= $('#pacakgesdropdown').val();
                    this.$router.push("/package/"+slug);
                }
                if(this.$route.path=="/visas")
                {
                    var slug= $('#visasdropdown').val();
                    this.$router.push("/visa/"+slug);
                }
                if(this.$route.path=="/")
                {
                    var slug= $('#visasdropdown').val();
                    this.$router.push("/visa/"+slug);
                }
            }
        },
};
</script>

