<template>
    <section id="footer">
        <div class="wrapper">
            <div class="col-xl-12 float-left footer-slider p-0">
                <div class="bottom-wrapper">
                    <div class="why-us heading">
                        <h2 class="destination-heading">Why Book with us!</h2>
                    </div>
                    <div class="row pl-3">
                        <div class="col-lg-4 d-footer-svgs">
                            <img
                                src="@/assets/refund-change.svg"
                                class="svg-img "
                            />
                            <!-- <img
                                src="@/assets/Refund-new-icon.svg"
                                class="svg-img "
                            /> -->
                            <div class="refund-info">
                                <h4 class="svg-head mb-2">Refund or Change</h4>
                                <p class="svg-detail">
                                    We are here to make your experience incredible.
                                    Reach us by phone, email, or WhatsApp.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 d-footer-svgs">
                            <img
                                src="@/assets/lowest-prices.svg"
                                class="svg-img "
                            />
                            <!-- <img
                                src="@/assets/Price-new-icon.svg"
                                class="svg-img "
                            /> -->
                            <div class="lowest-info">
                                <h4 class="svg-head mb-2">Lowest Prices</h4>
                                <p class="svg-detail">
                                    With over 100+ direct contracts with the airlines &
                                    suppliers, YUGO.pk can save you more!
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-4 d-footer-svgs">
                            <img
                                src="@/assets/faster-access.svg"
                                class="svg-img"
                            />
                            <!-- <img
                                src="@/assets/faster-access-new-icon.svg"
                                class="svg-img"
                            /> -->
                            <div class="faster-info">
                                <h4 class="svg-head mb-2">Faster Access</h4>
                                <p class="svg-detail">
                                    Get instant itinerary on your email when you make
                                    your booking through YUGO.pk
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- responsive  -->
        <div class="footer-carousel col-xl-12 float-left pl-0 pr-0">
            <div class="owl-carousel owl-theme visa-steps" id="owl_carouselfooter" >
                <div class="item">
                    <img
                         src="@/assets/refund-change.svg"
                        class="svg-img "
                     />
                    <div class="refund-info">
                        <h4 class="svg-head mb-2">Refund or Change</h4>
                        <p class="svg-detail m-0">
                            We are here to make your experience incredible.
                            Reach us by phone, email, or WhatsApp.
                        </p>
                    </div>
                </div>
                <div class="item">
                    <img
                        src="@/assets/lowest-prices.svg"
                        class="svg-img "
                    />
                    <div class="lowest-info">
                        <h4 class="svg-head mb-2">Lowest Prices</h4>
                        <p class="svg-detail m-0">
                            With over 100+ direct contracts with the airlines &
                            suppliers, YUGO.pk can save you more!
                        </p>
                    </div>
                </div>
                <div class="item">
                    <img
                        src="@/assets/faster-access.svg"
                        class="svg-img"
                    />
                    <div class="faster-info">
                        <h4 class="svg-head mb-2">Faster Access</h4>
                        <p class="svg-detail m-0">
                            Get instant itinerary on your email when you make
                            your booking through YUGO.pk
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- end -->
        <div class="col-xl-12 float-left subscribe text-center">
        <section class="wrapper-section">
            <div class="text-xl-left text-center col-xl-6 p-0 float-left">
                <h4>Subscribe to get  <br> awesome<span class="orange-danger"> Discounts</span></h4>
            </div>
            <div class="col-xl-6 mt-xl-3 text-xl-left text-center p-0 float-left newsletter-wrap">
                <form>
                    <input type="text" v-model="email" id="newsletter_filed" placeholder="abc@abc.com">
                    <div class="wrap-subscribe-btn">
                        <button class="text-uppercase float-right rubik-semibold search_btn" type="button" id="newsletter_submit"
                        @click="subscribeNow()">Subscribe</button>
                    </div>
                </form>
                <div v-html="subscribed"></div>
            </div>
            <!-- wrapper -->
        </section>
        <!-- subscribe -->
        </div>
        <footer class="footer float-left w-100">
            <section class="wrapper-section">
                <div class="float-left sitemap-info">
                    <div class="ph-number w-100 float-left">
                        Got Questions ? Call us 24/7<br>
                        Call Us: <a class="rubik-bold number" href="tel:+923111123359">03 111 123 359</a>
                    </div>
                    <ul class="m-0 p-0 list-unstyled social-icons">
                        <li>
                            <a href="https://www.facebook.com/placesyugo" target="_blank" class="icons">
                                <span><i class="fab fa-facebook-f"></i></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UC_UlLK5PTQFRU5m-8s8Qymg" target="_blank" class="icons">
                                <span> <i class="fab fa-youtube"></i></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/places_yugo/?hl=en#" target="_blank" class="icons">
                                <span> <i class="fab fa-instagram"></i></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/placesyugo/mycompany/?viewAsMember=true" target="_blank" class="icons">
                                <span> <i class="fab fa-linkedin-in"></i></span>
                            </a>
                        </li>
                    </ul>
                    <!-- sitemap -->
                </div>
                <div class="float-left sitemap">
                    <h4 class="rubik-medium mb-3">YUGO Products</h4>
                    <!-- List Group -->
                    <ul class="m-0 p-0 list-unstyled">
                        <li><router-link :to="'/flights'">Flights</router-link></li>
                        <li><router-link :to="'/visas'">Visas</router-link></li>
                        <li><router-link :to="'/yugo-rewards'">YUGO Rewards</router-link></li>
                        <li><router-link :to="'/sitemap'">Site Map</router-link></li>
                    </ul>
                    <!-- End List Group -->
                </div>
                <div class="float-left sitemap">
                    <h4 class="rubik-medium mb-3">Company</h4>
                    <!-- List Group -->
                    <ul class="m-0 p-0 list-unstyled">
                        <li><router-link :to="'/about-us'">Our Story</router-link></li>
                        <li><router-link :to="'term-conditions'">Terms and Conditions</router-link></li>
                        <li><router-link :to="'careers'">Careers</router-link></li>
                    </ul>
                    <!-- End List Group -->
                </div>
                <div class="float-left sitemap">
                    <h4 class="rubik-medium mb-3">Support</h4>
                    <!-- List Group -->
                    <ul class="m-0 p-0 list-unstyled">
                        <li>
                            <router-link :to="'login'">My Account</router-link>
                        </li>
                        <li>
                            <router-link :to="'contact-us'">Contact Us</router-link>
                        </li>
                        <li>
                            <router-link :to="'privacy-policy'">Privacy Policy</router-link>
                        </li>
                        <li>
                            <router-link :to="'latest-travel-update'">Covid Update</router-link>
                        </li>
                    </ul>
                    <!-- End List Group -->
                </div>
                <div class="copyright float-left w-100">
                    <p class="mb-0">© 2022 YUGO PVT LTD. All rights reserved</p>
                </div>
                <!-- wrapper -->
            </section>
        </footer>
  </section>
</template>
<script>
import axios from 'axios';
export default {
    name: "Home",

    data() {
            return {
                email: '',
                subscribed: '',
            };
        }, beforeRouteEnter(to, from, next) {
            store.state.bgyugobanner = this.bgyugobanner;
            this.mounted();
            next();
        },
        mounted() {
            setTimeout(function(){
                var owl = $('#owl_carouselfooter');
                owl.owlCarousel({
                    items:1,
                    loop:true,
                    margin:10,
                    autoplay:true,
                    autoplayTimeout:3000,
                    autoplayHoverPause:true
                });
            }, 1);
            $('.play').on('click',function(){
                owl.trigger('play.owl.autoplay',[1000])
            });
        },
        methods: {
            subscribeNow()
            {
                const vm = this;
                axios.post('https://api.yugo.pk/api/subscribe', 
                {
                    email: vm.email,
                    headers: {}
                }).then(function (response) 
                {
                    vm.subscribed = response.data;
                    
                }).catch(error => {
                    if (error) {
                        console.log(error);
                    }
                });
            },
      },
};
</script>
<style scoped>
.col-xl-12.float-left.subscribe.text-center{
    background-image: url("../assets/subscribe-bg.jpg");
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    margin: 24px 0px 0px;
    padding: 28px 0px;
}


</style>

