<template>
<div>
     <div class="loader">
     <div class="wrapper">
        <img src="@/assets/loader-new.gif" class="loader">
    </div>
    </div>
</div>
</template>
<script>
export default {
    name: "Forget",

    components: { },
    data() {
            return{ 
            };
        },mounted() {
            
        },methods: {
          
    },
};
</script>
<style scoped>
.loader {
     position: fixed;
    width: 100%;
    height: 100%;
    background: #0a549c;
    top: 0;
    z-index: 9999999999;
    left: 0;
    right: 0;
    text-align: center;
}
img.loader {
    top: 20%;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    width: 307px;
    height: 239px;
}
</style>